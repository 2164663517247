import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthVerifyToken } from '../../api'


const PrivatRoute = ({ children }) => {
    const [authValidate, setAuthValidate] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            let token = localStorage.getItem('doxAndBoxPortalToken')
            const isValid = await AuthVerifyToken(token)
            if (isValid === 'Token Verify') {
                setAuthValidate(true)
            }
            else {
                alert('Session Timeout')
                localStorage.clear()
                setAuthValidate(false)
            }
            setLoading(true)
        }
        fetchData()
    }, [children])

    // const auth = localStorage.getItem('User_ID');
    return <>
        {
            loading ?
                authValidate ? <>{children}</> : <Navigate to={{ pathname: '/' }} />
                : <p>loading...</p>
        }
    </>
}

export default PrivatRoute;