import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Login from './component/Login/Login';
import Dashboard from './component/Dashboard/Dashboard';
import RecordPickup from './component/Request/RecordPickup/RecordPickup';
import RecordRetrival from './component/Request/RecordRetrival/RecordRetrival';
import ScanningRequest from './component/Request/ScanningRequest/ScanningRequest';
import Shredding from './component/Request/Shredding/Shredding';
import OtherRequest from './component/Request/Other/OtherRequest';
import ScanningReports from './component/Response/ScanningReports/ScanningReports';
import InwardReports from './component/Response/InwardReports/InwardReports';
import RetrivalReports from './component/Response/RetrivalReport/RetrivalReport';
import StockReports from './component/Response/StockReports/StockReports';
import Profile from './component/Profile/Profile';
import PrivatRoute from './component/HOC/PrivateRoute';
import Changepassword from './component/changepassword/Changepassword';
import RecorRetrival from './component/Response/RecordRetrival/RecordRetrival'
import PickupReports from './component/Response/PickupReports/PickupReport';
import OtherReport from './component/Response/OtherRequestReport/OtherRequest';
import ShreddingRequest from './component/Response/ShreddingReports/ShreddingRequest';
import ScanningRequestReport from './component/Response/ScanningRequestReport/ScanningRequest';
import BoxReport from './component/Response/BoxReport/Boxreport'
import ScanningBasicdetails from './component/Users/ScanningBasicdetails';
import PickupBasicdetails from './component/Users/PickupBasicDetails';
import UserLogindetails from './component/Users/UserLogindetails';
import UpdateScanningBasicdetails from './component/Users/UpdateScanningBasicdetails';
import UpdatePickupBasicDetails from './component/Users/UpdatePickupBasicDetails';
import PageNotFound from './component/PageNotFound/PageNotFound'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path='/Profile' element={<PrivatRoute> <Profile /> </PrivatRoute>} />
          <Route path='/Dashboard' element={<PrivatRoute> <Dashboard /> </PrivatRoute>} />
          <Route path='/RecordPickup' element={<PrivatRoute> <RecordPickup /> </PrivatRoute>} />
          <Route path='/RecordRetrival' element={<PrivatRoute> <RecordRetrival /> </PrivatRoute>} />
          <Route path='/ScanningRequest' element={<PrivatRoute> <ScanningRequest /> </PrivatRoute>} />
          <Route path='/Shredding' element={<PrivatRoute> <Shredding /> </PrivatRoute>} />
          <Route path='/OtherRequest' element={<PrivatRoute> <OtherRequest /> </PrivatRoute>} />
          <Route path='/ScanningReports' element={<PrivatRoute> <ScanningReports /> </PrivatRoute>} />
          <Route path='/InwardReports' element={<PrivatRoute> <InwardReports /> </PrivatRoute>} />
          <Route path='/RetrivalReports' element={<PrivatRoute> <RetrivalReports /> </PrivatRoute>} />
          <Route path='/StockReports' element={<PrivatRoute> <StockReports /> </PrivatRoute>} />
          <Route path='/Changepassword' element={<PrivatRoute> <Changepassword /> </PrivatRoute>} />
          <Route path='/RecorRetrivalReport' element={<PrivatRoute> <RecorRetrival /> </PrivatRoute>} />
          <Route path='/RecordPickupReport' element={<PrivatRoute> <PickupReports /> </PrivatRoute>} />
          <Route path='/OtherRequestReport' element={<PrivatRoute> <OtherReport /> </PrivatRoute>} />
          <Route path='/ShreddingRequestReport' element={<PrivatRoute> <ShreddingRequest /> </PrivatRoute>} />
          <Route path='/ScanningRequestReport' element={<PrivatRoute> <ScanningRequestReport /> </PrivatRoute>} />
          <Route path='/BoxReport' element={<PrivatRoute> <BoxReport /> </PrivatRoute>} />
          <Route path='/ScanningBasicDetails' element={<PrivatRoute> <ScanningBasicdetails /> </PrivatRoute>} />
          <Route path='/PickupBasicdetails' element={<PrivatRoute> <PickupBasicdetails /> </PrivatRoute>} />
          <Route path='/UserLogindetails' element={<PrivatRoute> <UserLogindetails /> </PrivatRoute>} />
          <Route path='/updatescanningbasicdetails' element={<PrivatRoute> <UpdateScanningBasicdetails /> </PrivatRoute>} />
          <Route path='/updatepickupbasicdetails' element={<PrivatRoute> <UpdatePickupBasicDetails /> </PrivatRoute>} />

          {/* <PrivatRoute path="/Profile" exact component={Profile}/>
                <PrivatRoute path="/Dashboard"   component={Dashboard} />
                <PrivatRoute path="/RecordPickup"   component={RecordPickup}/>
                <PrivatRoute path="/RecordRetrival"  component={RecordRetrival}/>
                <PrivatRoute path="/ScanningRequest"   component={ScanningRequest}/>
                <PrivatRoute path="/Shredding"   component={Shredding}/>
                <PrivatRoute path="/OtherRequest"   component={OtherRequest}/>
                <PrivatRoute path="/ScanningReports"   component={ScanningReports}/>
                <PrivatRoute path="/InwardReports"   component={InwardReports}/>
                <PrivatRoute path="/RetrivalReports"   component={RetrivalReports}/>
                <PrivatRoute path="/StockReports"   component={StockReports}/>
                <PrivatRoute path="/Changepassword"   component={Changepassword}/>
                <PrivatRoute path="/RecorRetrivalReport"   component={RecorRetrival}/>
                <PrivatRoute path="/RecordPickupReport"   component={PickupReports}/>
                <PrivatRoute path="/OtherRequestReport"   component={OtherReport}/>
                <PrivatRoute path="/ShreddingRequestReport"   component={ShreddingRequest}/>
                <PrivatRoute path="/ScanningRequestReport"   component={ScanningRequestReport}/>
                <PrivatRoute path="/BoxReport"   component={BoxReport}/>

                <PrivatRoute path="/ScanningBasicDetails"   component={ScanningBasicdetails}/>
                <PrivatRoute path="/PickupBasicdetails"   component={PickupBasicdetails}/>
                <PrivatRoute path="/UserLogindetails"   component={UserLogindetails}/>
                <PrivatRoute path="/updatescanningbasicdetails"   component={UpdateScanningBasicdetails}/>
                <PrivatRoute path="/updatepickupbasicdetails"   component={UpdatePickupBasicDetails}/> */}

          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
