import axios from 'axios';
const BASE_URL = `https://portalbackend.doxandbox.com/api`
// const BASE_URL = `http://localhost:8009/api`

export const UserLogin = async (uid_id, uid_pass) => {
    return axios.post(`${BASE_URL}/UserLogin`, { uid_id, uid_pass }).then(response => response.data).catch(error => console.log(error));
}
export const AuthVerifyToken = async (token) => {
    return axios.post(`${BASE_URL}/AuthVerifyToken`, { token }).then(response => response.data).catch(err => console.log(err))
}

export const PasswordChange = async (uid_id, uid_pass, whid, new_password) => {
    return axios.post(`${BASE_URL}/userpasswordchange`, { uid_id, uid_pass, whid, new_password }).then(response => response.data).catch(error => console.log(error));
}

export const ProfileDetails = async (uid_id) => {
    return axios.post(`${BASE_URL}/profiledetails`, { uid_id }).then(response => response.data).catch(error => console.log(error));
}

export const rmsReports = async (reportType, custId, Whid, startDate, endDate, department) => {
    return axios.post(`${BASE_URL}/reports`, { reportType, custId, Whid, startDate, endDate, department }).then(response => response.data).catch(error => console.log(error));
}

export const rmsRequest = async (request_type, location, noof_files, request_date, request_time, file_name, retrival_type, delivery_type, noof_pages, onsite, activity, remark, entry_by, FILEID, WHID, requestid, custid, TYPE, Contactperson, Personno, Deparment, Boxno, DESCN, Bookingid, city) => {
    return axios.post(`${BASE_URL}/request`, { request_type, location, noof_files, request_date, request_time, file_name, retrival_type, delivery_type, noof_pages, onsite, activity, remark, entry_by, FILEID, WHID, requestid, custid, TYPE, Contactperson, Personno, Deparment, Boxno, DESCN, Bookingid, city }).then(response => response.data).catch(error => console.log(error));
}

export const ReportData = async (uid_id, location_id, department) => {
    return axios.post(`${BASE_URL}/reportdata`, { uid_id, location_id, department }).then(response => response.data).catch(error => console.log(error));
}

export const Mail = async (requestData) => {
    return axios.post(`${BASE_URL}/mail`, { requestData }).then(response => response.data).catch(error => console.log(error));
}

export const Totallocation = async (custid) => {
    return axios.post(`${BASE_URL}/totallocation`, { custid }).then(response => response.data).catch(error => console.log(error));
}

export const Dashboardetails = async (CUSTID, wh) => {
    return axios.post(`${BASE_URL}/dashboardetails`, { CUSTID, wh }).then(response => response.data).catch(error => console.log(error));
}

export const ShreddingDupliacte = async (files, CUSTID, WH) => {
    return axios.post(`${BASE_URL}/shreddingduplicate`, { files, CUSTID, WH }).then(response => response.data).catch(error => console.log(error));
}

export const ReportdataBoxes = async (uid_id, location_id, department) => {
    return axios.post(`${BASE_URL}/reportdataboxes`, { uid_id, location_id, department }).then(response => response.data).catch(error => console.log(error));
}

export const Dashboardrequest = async () => {
    return axios.get(`${BASE_URL}/bargraph`).then(response => response.data).catch(error => console.log(error));
}

export const DashbaordetailsPie = async (CUSTID, wh) => {
    return axios.post(`${BASE_URL}/piegraph`, { CUSTID, wh }).then(response => response.data).catch(error => console.log(error));
}

export const RequestReport = async (cust_id, request_type) => {
    return axios.post(`${BASE_URL}/requestreport`, { cust_id, request_type }).then(response => response.data).catch(error => console.log(error));
}

export const BoxReports = async (Boxno, CUSTID, WH) => {
    return axios.post(`${BASE_URL}/boxreport`, { Boxno, CUSTID, WH }).then(response => response.data).catch(error => console.log(error));
}

export const GenerateTwofa = async (email, org) => {
    return axios.post(`${BASE_URL}/Twofa`, { email, org }).then(response => response.data).catch(error => console.log(error));
}

export const Verify2fa = async (secret, otp, userid) => {
    return axios.post(`${BASE_URL}/VerifyTwo`, { secret, otp, userid }).then(response => response.data).catch(error => console.log(error));
}

export const TotalScanReportCount = async (custid, wh, startdate, enddate) => {
    return axios.post(`${BASE_URL}/totalscanreportcount`, { custid, wh, startdate, enddate }).then(response => response.data).catch(error => console.log(error));
}

export const IdCount = async (whid) => {
    return axios.post(`${BASE_URL}/idcount`, { whid }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateIdCount = async (whid, Idcount) => {
    return axios.post(`${BASE_URL}/updateidcount`, { whid, Idcount }).then(response => response.data).catch(error => console.log(error));
}

export const dashbaorScannedPages = async (custid, whid) => {
    return axios.post(`${BASE_URL}/dashbaorscannedpages`, { custid, whid }).then(response => response.data).catch(error => console.log(error));
}

export const DepartmentData = async (uid_id, location_id) => {
    return axios.post(`${BASE_URL}/departmentdata`, { uid_id, location_id }).then(response => response.data).catch(error => console.log(error));
}

export const FileUpload = async (images) => {
    return axios.post(`${BASE_URL}/FileUpload`, images).then(response => response.data).catch(error => console.log(error));
}

export const lastcount = async (whid) => {
    return axios.post(`${BASE_URL}/lastcount`, { whid }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateCount = async (whid, Idcount) => {
    return axios.post(`${BASE_URL}/updatecount`, { whid, Idcount }).then(response => response.data).catch(error => console.log(error));
}

export const insertscannerportaldetails = async (Requestid, Requesttype, StartReading, Endreading, Arriveddate, ArrivedTime, Imagelink, Totalpagesscan, Remarks, EntryBy, Noboxes, Nooffiles, Activity, Portalid, Assetid, Assetname, ActivityGLcode, username) => {
    return axios.post(`${BASE_URL}/insertscannerportaldetails`, { Requestid, Requesttype, StartReading, Endreading, Arriveddate, ArrivedTime, Imagelink, Totalpagesscan, Remarks, EntryBy, Noboxes, Nooffiles, Activity, Portalid, Assetid, Assetname, ActivityGLcode, username }).then(response => response.data).catch(error => console.log(error));
}
export const insertpickupportaldetails = async (Requestid, Requesttype, StartReading, Endreading, Arriveddate, ArrivedTime, Imagelink, Totalpagesscan, Remarks, EntryBy, Noboxes, Nooffiles, Activity, Portalid, Assetid, Assetname, ActivityGLcode, username) => {
    return axios.post(`${BASE_URL}/insertpickupportaldetails`, { Requestid, Requesttype, StartReading, Endreading, Arriveddate, ArrivedTime, Imagelink, Totalpagesscan, Remarks, EntryBy, Noboxes, Nooffiles, Activity, Portalid, Assetid, Assetname, ActivityGLcode, username }).then(response => response.data).catch(error => console.log(error));
}

export const scannerportaldatamorethanone = async (EntryBy, Arriveddate, Requesttype, Requestid) => {
    return axios.post(`${BASE_URL}/scannerportaldatamorethanone`, { EntryBy, Arriveddate, Requesttype, Requestid }).then(response => response.data).catch(error => console.log(error));
}

export const totalportalrequest = async (EntryBy, requesttype) => {
    return axios.post(`${BASE_URL}/totalportalrequest`, { EntryBy, requesttype }).then(response => response.data).catch(error => console.log(error));
}

export const totalscannerdetails = async (Requestid) => {
    return axios.post(`${BASE_URL}/totalscannerdetails`, { Requestid }).then(response => response.data).catch(error => console.log(error));
}

export const getportalrequest = async (Portalid) => {
    return axios.post(`${BASE_URL}/getportalrequest`, { Portalid }).then(response => response.data).catch(error => console.log(error));
}

export const updateportalrequest = async (Endreading, Totalpagesscan, Noboxes, Nooffiles, Activity, Remarks, Portalid) => {
    return axios.post(`${BASE_URL}/updateportalrequest`, { Endreading, Totalpagesscan, Noboxes, Nooffiles, Activity, Remarks, Portalid }).then(response => response.data).catch(error => console.log(error));
}

export const Activityrequest = async () => {
    return axios.post(`${BASE_URL}/activityrequest`).then(response => response.data).catch(error => console.log(error));
}

export const Requestidforuser = async (empid, reqtype) => {
    return axios.post(`${BASE_URL}/requestidforuser`, { empid, reqtype }).then(response => response.data).catch(error => console.log(error));
}

export const fileuploadindb = async (portalid, requestid, custid, Imagelink, EntryBy) => {
    return axios.post(`${BASE_URL}/fileuploadindb`, { portalid, requestid, custid, Imagelink, EntryBy }).then(response => response.data).catch(error => console.log(error));
}